import {
  createSlice,
  PayloadAction,
  ThunkDispatch,
  AnyAction
} from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { Dispatch } from 'react';

import axios from '../../utils/axios';
import { sortByProperty } from '../../utils/sortValues';
import { HeaderTableFilter } from '../../@types/filters';
import { OrdersState } from '../../@types/reduxStates';
import { OrderFiltersState } from '../../@types/filterBars';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { OrderRow } from '../../@types/tableRows';
// import { ChipItem } from '../../@types/products';

const initialState: OrdersState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    status: [],
    customerList: [],
    sourceChannels: [],
    amount: [0, 0],
    margin: [0, 0],
    countryId: 0,
    mandantShops: [],
    orderType: [],
    searchOrders: '',
    dateSelectorType: 0
  },
  response: {
    rows: [],
    total: {
      amountTotal: 0,
      amountTotalNet: 0,
      itemsTotal: 0,
      marginTotal: 0
    },
    totalCount: {
      count: 0
    }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrderList(state: any, action: PayloadAction<Array<OrderRow>>) {
      state.data = action.payload;
    },
    setFilters: (state: any, action: PayloadAction<OrderFiltersState>) => {
      state.filters = action.payload;
    },
    getOrderList: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getOrderListSuccess: (
      state: any,
      action: PayloadAction<Array<OrderRow>>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.data = action.payload;
    },
    getOrderListError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    },
    sortOrderList: (state: any, action: PayloadAction<Array<OrderRow>>) => {
      state.data = action.payload;
    }
  }
});

export function fetchFilterOrdersData(
  filters: OrderFiltersState,
  idArticle: number = 0
) {
  return async (dispatch: any) => {
    try {
      dispatch(setFilters(filters));
      dispatch(getOrderList());
      // let response: AxiosResponse<any>;
      const params = {
        idArticle,
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        status:
          filters.status.length > 0
            ? filters.status.map((t, i) => `${t.id}`).join(',')
            : undefined,
        sourceIds:
          filters.sourceChannels.length > 0
            ? filters.sourceChannels.map((t, i) => `${t.id}`).join(',')
            : undefined
      };
      const response = await axios.get('/rest/v1/dashboard/orderList', {
        params
      });
      dispatch(getOrderListSuccess(response.data));
    } catch (error) {
      dispatch(getOrderListError(error as APIError));
    }
  };
}

/* let fullQuery = `/rest/v1/dashboard/orderList`;
    let fullQuery2 = `/rest/v1/article/orderList`;
    fullQuery = `${fullQuery}?from=${filters.timePeriod.startFilter}&till=${filters.timePeriod.endFilter}`; */
/* if (filters.searchText.length > 0) {
      fullQuery = `${fullQuery}&search=${filters.searchText}`;
    } */
/* if (Number(filters.status) > 0) {
      fullQuery = `${fullQuery}&orderStatusId=${filters.status}`;
    }
    if (filters.channelTags.length > 0) {
      fullQuery = `${fullQuery}&sourceId=${filters.channelTags
        .map((t, i) => `${t.sourceId}`)
        .join(',')}`; */
/* fullQuery = `${fullQuery}&tags=${filters.channelTags
        .map((t, i) => `tags[${i}]=${t.shopId}`)
        .join('&')}`; 
         
        const chipLabels = () => {
        if (tags && tags.length > 0) {
          return `/tags?${tags
            .map((t, i) => `tags[${i}]=${t.label}`)
            .join('&')}`;
        }
        return '';
      };
        
        */

/* if (filters.campaignCountryId > 0) {
      fullQuery = `${fullQuery}&campaignCountryId=${filters.campaignCountryId}`;
    } */

/*  response = await axios.get(fullQuery); */

// }

export function sortBy(
  list: Array<OrderRow>,
  orderBy: string,
  order: 'asc' | 'desc',
  orderType: HeaderTableFilter
) {
  const orderedList = sortByProperty(list, orderBy, order, orderType);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(sortOrderList(orderedList));
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilters,
  setOrderList,
  getOrderList,
  getOrderListSuccess,
  getOrderListError,
  sortOrderList
} = slice.actions;

/* const data: Array<OrderResponse> = [
  {
    orderId: {
      external: 'external-001',
      internal: 'internal-001'
    },
    channel: {
      src: '/static/brand/DraftAlgoritmo.png',
      name: 'Zalando'
    },
    date: '10-02-2022',
    status: 'in_process',
    payment: {
      id: 100,
      pluginKey: 'payment-pluginKey-001',
      paymentKey: 'payment-paymentKey-001',
      name: 'payment',
      src: 'payment-src-001'
    },
    shipCountry: 'de-DE',
    items: 120,
    amount: {
      gross: 1000,
      neto: 900
    },
    margin: 45
  },
  {
    orderId: {
      external: 'external-002',
      internal: 'internal-002'
    },
    channel: {
      src: '/static/brand/DraftAlgoritmo.png',
      name: 'bol.com'
    },
    date: '10-02-2022',
    status: 'open',
    payment: {
      id: 100,
      pluginKey: 'payment-pluginKey-002',
      paymentKey: 'payment-paymentKey-002',
      name: 'payment',
      src: 'payment-src-002'
    },
    shipCountry: 'bz-BZ',
    items: 34,
    amount: {
      gross: 10000,
      neto: 9344
    },
    margin: 12
  },
  {
    orderId: {
      external: 'external-003',
      internal: 'internal-003'
    },
    channel: {
      src: '/static/brand/logo_full.png',
      name: 'Zalando'
    },
    date: '10-02-2022',
    status: 'cancelation',
    payment: {
      id: 100,
      pluginKey: 'payment-pluginKey-003',
      paymentKey: 'payment-paymentKey-003',
      name: 'payment',
      src: 'payment-src-003'
    },
    shipCountry: 'af-AF',
    items: 2,
    amount: {
      gross: 1000,
      neto: 900
    },
    margin: 45
  },
  {
    orderId: {
      external: 'external-004',
      internal: 'internal-004'
    },
    channel: {
      src: '/static/brand/logo_algoritmo.png',
      name: 'ebay'
    },
    date: '10-02-2022',
    status: 'refund',
    payment: {
      id: 100,
      pluginKey: 'payment-pluginKey-004',
      paymentKey: 'payment-paymentKey-004',
      name: 'payment',
      src: 'payment-src-004'
    },
    shipCountry: 'us-US',
    items: 32,
    amount: {
      gross: 230,
      neto: 150
    },
    margin: 56
  },
  {
    orderId: {
      external: 'external-005',
      internal: 'internal-005'
    },
    channel: {
      src: '/static/brand/DraftAlgoritmo.png',
      name: 'ebay'
    },
    date: '10-02-2022',
    status: 'shipped',
    payment: {
      id: 100,
      pluginKey: 'payment-pluginKey-005',
      paymentKey: 'payment-paymentKey-005',
      name: 'payment',
      src: 'payment-src-005'
    },
    shipCountry: 'br-BR',
    items: 1223,
    amount: {
      gross: 2000,
      neto: 1600
    },
    margin: 75
  },
  {
    orderId: {
      external: 'external-006',
      internal: 'internal-006'
    },
    channel: {
      src: '/static/brand/DraftAlgoritmo.png',
      name: 'Plenty source'
    },
    date: '10-02-2022',
    status: 'shipped',
    payment: {
      id: 100,
      pluginKey: 'payment-pluginKey-006',
      paymentKey: 'payment-paymentKey-006',
      name: 'payment',
      src: 'payment-src-006'
    },
    shipCountry: 'es-ES',
    items: 12,
    amount: {
      gross: 1534,
      neto: 1230
    },
    margin: 90
  }
]; */
