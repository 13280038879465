import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import axios from '../../../utils/axios';

import {
  ChartEdiSummaryStateReponse,
  ChartEdiSummaryState
} from '../../../@types/acs';

import { APIStatus, APIError } from '../../../@types/APIStatus';
import { ACSFiltersState } from '../../../@types/filterBars';

const initialState: ChartEdiSummaryState = {
  params: '',
  APIStatus: APIStatus.IDLE,
  data: {
    xaxis: [],
    messages_send: {
      tending: 0,
      total: 0,
      data: []
    },
    messages_recieved: {
      tending: 0,
      total: 0,
      data: []
    },
    messages_pending: {
      tending: 0,
      total: 0,
      data: []
    },
    messages_warning: {
      tending: 0,
      total: 0,
      data: []
    },
    messages_error: {
      tending: 0,
      total: 0,
      data: []
    }
  }
};

const slice = createSlice({
  name: 'ChartEdiSummary',
  initialState,
  reducers: {
    setUrlParams(state: ChartEdiSummaryState, action: PayloadAction<string>) {
      state.params = action.payload;
    },
    setChartEdiSummary(
      state: ChartEdiSummaryState,
      action: PayloadAction<ChartEdiSummaryStateReponse>
    ) {
      state.data = action.payload;
    },
    getChartEdiSummary: (state: ChartEdiSummaryState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getChartEdiSummarySuccess: (
      state: ChartEdiSummaryState,
      action: PayloadAction<ChartEdiSummaryStateReponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.data = action.payload;
    },
    getChartEdiSummaryError: (
      state: ChartEdiSummaryState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchChartEdiSummary(
  abortController: AbortController,
  params: Record<string, any>
) {
  return async (dispatch: any) => {
    try {
      dispatch(getChartEdiSummary());
      const response = await axios.get('/api/v2/acs/ediChartEdiSummary', {
        signal: abortController.signal,
        params
      });
      dispatch(getChartEdiSummarySuccess(response.data));
    } catch (error) {
      dispatch(getChartEdiSummaryError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setChartEdiSummary,
  getChartEdiSummary,
  getChartEdiSummarySuccess,
  getChartEdiSummaryError,
  setUrlParams
} = slice.actions;
