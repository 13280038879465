import { Theme, Box, makeStyles } from '@material-ui/core';

import { Icon } from '@iconify/react';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useConstants } from '../../ConstantsContext';
import TableStickyHeader from './TableStickyHeader';
import { TableHeaderCell } from '../../@types/tableRows';
import { SortBy } from '../../@types/filters';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const useStyles = makeStyles<Theme, { leftStart: number; y: number }>(
  (theme) => ({
    arrow: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '100%',
      width: '200px',
      overflow: 'hidden',
      position: 'absolute',
      top: '0px',
      zIndex: 10,
      '&.left': {
        left: ({ leftStart }) => `${leftStart}px`,
        [theme.breakpoints.down('md')]: {
          left: '0px'
        },
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity .2s ease, visibility 0s 1s, width 0s 1s',
        '&.visible': {
          opacity: 1,
          visibility: 'visible',
          transition: 'opacity .2s ease, visibility 0s, width 0s'
        }
      },
      '&.right': {
        justifyContent: 'flex-end',
        right: '0px',
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity .2s ease, visibility 0s 1s, width 0s 1s',
        '&.visible': {
          opacity: 1,
          visibility: 'visible',
          transition: 'opacity .2s ease, visibility 0s, width 0s'
        }
      }
    },
    icon: {
      position: 'absolute',
      top: ({ y }) => `${y}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0.5,
      backgroundImage: `linear-gradient(to right, rgba(168,27,71,.5), rgba(168,27,71,1))`,
      height: '100px',
      width: '50px',
      '&.right': {
        borderRadius: '50px 0px 0px 50px'
      },
      '&.left': {
        borderRadius: '0px 50px 50px 0px'
      },
      '& svg': {
        color: theme.palette.common.white
      },
      '&:hover': {
        backgroundImage: `linear-gradient(to right, rgba(168,27,71,.7), rgba(168,27,71,1))`,
        opacity: 1
      }
    }
  })
);

type Props = {
  children: React.ReactNode;
  forwardedRef: RefObject<HTMLTableRowElement>;
  summary: Array<TableHeaderCell>;
  sortBy: SortBy;
  onSortChange: (sortBy: SortBy) => void;
  leftStart?: number;
  showSummary?: boolean;
};

const TableHorizontalScroll: React.FC<Props> = ({
  children,
  forwardedRef,
  summary,
  sortBy,
  onSortChange,
  leftStart = 220,
  showSummary = true
}) => {
  const { APPBAR_DESKTOP, APPBAR_MOBILE } = useConstants();
  const isTouchDevice = 'ontouchstart' in document.documentElement;
  const positionHeader = isTouchDevice
    ? APPBAR_MOBILE + (showSummary ? 120 : 60)
    : APPBAR_DESKTOP + (showSummary ? 120 : 60);
  const rootMargin = `-${positionHeader}px 0px 0px 0px`;

  const isHeaderVisible = useIntersectionObserver(forwardedRef, { rootMargin });

  const [mousePositionY, setMousePositionY] = useState<number>(0);
  const [showLeft, setShowLeft] = useState<boolean>(false);
  const [showRight, setShowRight] = useState<boolean>(false);
  const scrollSpeedRef = useRef<number>(0);
  const mousePositionYRef = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const myRef = useRef<HTMLDivElement | null>(null);
  const stickyHeaderRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const classesScroll = useStyles({ leftStart, y: mousePositionY });

  const timeRef = useRef<NodeJS.Timeout | null>(null);

  const getScrollSpeedRef = () => scrollSpeedRef.current;
  const getMouseYPosition = () => mousePositionYRef.current;

  const handleComponentScroll = (scrollXPosition: number, maxValue: number) => {
    if (scrollXPosition <= 0) {
      setShowLeft(false);
    } else {
      setShowLeft(true);
    }
    if (scrollXPosition >= maxValue) {
      setShowRight(false);
    } else {
      setShowRight(true);
    }
  };

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement>,
    direction: number
  ) => {
    if (!isTouchDevice) {
      timeRef.current = setInterval(() => {
        const mY = getMouseYPosition();
        setMousePositionY(mY);
        if (myRef && myRef.current) {
          const steps = direction === 2 ? 30 : -30;
          const scrollLeft = steps * getScrollSpeedRef();
          myRef.current.scrollLeft += scrollLeft;
          handleComponentScroll(
            myRef.current.scrollLeft,
            myRef.current.scrollWidth - myRef.current.offsetWidth
          );
          if (stickyHeaderRef && stickyHeaderRef.current) {
            stickyHeaderRef.current.scrollLeft += scrollLeft;
          }
        }
      }, 25);
    }
  };

  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      if (timeRef.current) {
        clearInterval(timeRef.current);
      }
    }
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLDivElement>,
    arrowDirection: number
  ) => {
    const box = event.currentTarget;
    const rect = box.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const boxWidth = rect.width;
    const positionProportion = mouseX / boxWidth;
    const scrollSpeed =
      arrowDirection === 2 ? positionProportion : 1 - positionProportion;
    scrollSpeedRef.current = scrollSpeed;
  };

  const handleMouseEnterTable = () => {
    if (myRef && myRef.current) {
      handleComponentScroll(
        myRef.current.scrollLeft,
        myRef.current.scrollWidth - myRef.current.offsetWidth
      );
    }
  };

  const handleMouseLeaveTable = () => {
    setShowLeft(false);
    setShowRight(false);
  };

  const handleScroll = () => {
    if (stickyHeaderRef.current && tableRef.current) {
      stickyHeaderRef.current.scrollLeft = tableRef.current.scrollLeft;
    }
  };

  useEffect(() => {
    if (stickyHeaderRef && stickyHeaderRef.current) {
      if (isHeaderVisible) {
        stickyHeaderRef.current.style.visibility = 'hidden';
      } else {
        stickyHeaderRef.current.style.visibility = 'visible';
      }
    }
  }, [isHeaderVisible]);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const indexOriginal = isTouchDevice ? 1 : 3;
      tableRef.current = containerRef.current.children[
        indexOriginal
      ] as HTMLDivElement;
      if (tableRef && tableRef.current) {
        tableRef.current.addEventListener('scroll', handleScroll);
      }
      myRef.current = containerRef.current.children[
        indexOriginal
      ] as HTMLDivElement;
      if (myRef && myRef.current) {
        const rect = myRef.current.getBoundingClientRect();
        stickyHeaderRef.current = containerRef.current.children[
          indexOriginal - 1
        ] as HTMLDivElement;
        if (stickyHeaderRef && stickyHeaderRef.current) {
          stickyHeaderRef.current.style.left = `${rect.left}px`;
          stickyHeaderRef.current.style.width = `${rect.width}px`;
        }
      }
    }
    return () => {
      if (tableRef && tableRef.current) {
        tableRef.current.removeEventListener('scroll', handleScroll);
      }
      if (timeRef && timeRef.current) {
        clearInterval(timeRef.current);
      }
    };
  }, [isTouchDevice, forwardedRef]);

  const handleMouseMoveInTable = (event: React.MouseEvent<HTMLDivElement>) => {
    const box = event.currentTarget;
    const rect = box.getBoundingClientRect();
    const mouseY = event.clientY - rect.top - 50;
    mousePositionYRef.current = mouseY;
    if (myRef && myRef.current) {
      handleComponentScroll(
        myRef.current.scrollLeft,
        myRef.current.scrollWidth - myRef.current.offsetWidth
      );
    }
  };

  return !isTouchDevice ? (
    <Box
      ref={containerRef}
      sx={{ position: 'relative' }}
      onMouseEnter={handleMouseEnterTable}
      onMouseLeave={handleMouseLeaveTable}
      onMouseMove={handleMouseMoveInTable}
    >
      <Box
        className={`${classesScroll.arrow} left${showLeft ? ' visible' : ''}`}
        onMouseEnter={(e) => handleMouseEnter(e, 1)}
        onMouseLeave={handleMouseLeave}
        onMouseMove={(e) => handleMouseMove(e, 1)}
      >
        <Box className={`${classesScroll.icon} left`}>
          <Icon icon="eva:arrow-ios-back-outline" width={42} height={42} />
        </Box>
      </Box>

      <Box
        className={`${classesScroll.arrow} right${showRight ? ' visible' : ''}`}
        onMouseEnter={(e) => handleMouseEnter(e, 2)}
        onMouseLeave={handleMouseLeave}
        onMouseMove={(e) => handleMouseMove(e, 2)}
      >
        <Box className={`${classesScroll.icon} right`}>
          <Icon icon="eva:arrow-ios-forward-outline" width={42} height={42} />
        </Box>
      </Box>

      <TableStickyHeader
        onSortChange={onSortChange}
        sortBy={sortBy}
        summary={summary}
        showSummary={showSummary}
      />
      {children}
    </Box>
  ) : (
    <Box ref={containerRef} sx={{ position: 'relative' }}>
      <TableStickyHeader
        onSortChange={onSortChange}
        sortBy={sortBy}
        summary={summary}
        showSummary={showSummary}
      />
      {children}
    </Box>
  );
};

export default TableHorizontalScroll;
