import jwtDecode from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
//
import axios from '../../utils/axios';
// @types
import { User } from '../../@types/account';

// ----------------------------------------------------------------------

type AuthJWTState = {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: User;
};

const unAuthUser = {
  id: '',
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  password: '',
  photoURL: null,
  phoneNumber: null,
  country: null,
  address: null,
  city: null,
  zipCode: null,
  about: null,
  role: '',
  isPublic: true,
  isActive: false,
  isAdmin: false,
  uuid: '',
  stockLow: 3,
  currency: {
    html: '&euro;',
    iso: 'EUR',
    sign: '€',
    text: 'Euro'
  }
};

const initialState: AuthJWTState = {
  isLoading: false,
  isAuthenticated: false,
  user: unAuthUser
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // VERIFICATON
    verifySuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // UPDATE
    updateSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = unAuthUser;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Math.floor(Date.now() / 1000);

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ----------------------------------------------------------------------

export function login({
  email,
  password,
  remember
}: {
  email: string;
  password: string;
  remember: boolean;
}) {
  return async () => {
    const { dispatch } = store;
    const response = await axios.post('/api/v1/account/login', {
      email,
      password,
      remember
    });
    const { accessToken, user } = response.data;
    setSession(accessToken);
    dispatch(slice.actions.loginSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function register({
  email,
  password,
  firstName,
  lastName,
  iso
}: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  iso: string;
}) {
  return async () => {
    const { dispatch } = store;

    const response = await axios.post('/rest/v1/account/register', {
      email,
      password,
      firstName,
      lastName,
      iso
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function resetPassword({ email, iso }: { email: string; iso: string }) {
  return async () => {
    await axios.put('/api/v1/account/reset', {
      email,
      iso
    });
  };
}

export function verifyCode({ code, email }: { code: string; email: string }) {
  return async () => {
    const { dispatch } = store;
    const response = await axios.post('/rest/v1/account/verify', {
      code,
      email
    });

    const { accessToken, user } = response.data;
    setSession(accessToken);
    dispatch(slice.actions.verifySuccess({ user }));
  };
}

export function updatePassword({
  oldPassword,
  newPassword,
  confirmNewPassword,
  iso,
  uuid
}: {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  iso: string;
  uuid: string;
}) {
  return async () => {
    await axios.put('/api/v1/user/password', {
      oldPassword,
      newPassword,
      confirmNewPassword,
      iso,
      uuid
    });
  };
}

export function updateProfile({
  company,
  firstName,
  lastName,
  photoURL,
  phoneNumber,
  country,
  address,
  city,
  zipCode,
  isPublic,
  uuid
}: {
  company: string;
  firstName: string;
  lastName: string;
  photoURL: string;
  phoneNumber: string;
  country: string;
  address: string;
  city: string;
  zipCode: string;
  isPublic: boolean;
  uuid: string;
}) {
  return async () => {
    const { dispatch } = store;
    const response = await axios.put('/api/v1/user/update', {
      company,
      firstName,
      lastName,
      photoURL,
      phoneNumber,
      country,
      address,
      city,
      zipCode,
      isPublic,
      uuid
    });

    const { user } = response.data;
    dispatch(slice.actions.updateSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async () => {
    const { dispatch } = store;
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get('/api/v1/account/my-account');
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: response.data.user
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: unAuthUser
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
