import { alpha, Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles<Theme, { color: string; noData: boolean }>(
  (theme) => ({
    root: {
      height: ({ noData }) => (noData ? 'auto' : '100%'),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: ({ noData }) => (noData ? 'flex-start' : 'center'),
      alignItems: 'center'
    },
    status: {
      borderRadius: 7,
      padding: '4px 10px',
      marginTop: ({ noData }) => (noData ? 0 : 4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: ({ color }) => color,
      backgroundColor: ({ color }) => alpha(color, 0.1)
    }
  })
);

type StockStatus = {
  label: string;
  color: string;
};

function getStockStatus(
  lowStockConfig: number,
  currentStock?: number
): StockStatus {
  if (
    currentStock === null ||
    currentStock === undefined ||
    isNaN(currentStock)
  ) {
    return {
      label: 'component:stock_nodata',
      color: '#FF1943'
    };
  }
  if (currentStock <= 0) {
    return {
      label: 'component:stock_empty',
      color: '#FF1943'
    };
  }
  if (currentStock > 0 && currentStock <= lowStockConfig) {
    return {
      label: 'component:stock_low',
      color: '#FFA319'
    };
  }
  if (currentStock > lowStockConfig) {
    return {
      label: 'component:stock_available',
      color: '#57CA22'
    };
  }
  return {
    label: 'component:stock_nodata',
    color: '#FF1943'
  };
}

type StockCellProps = {
  stock?: number;
  stockLow: number;
};

export function CurrentStockCell({ stock, stockLow = 4 }: StockCellProps) {
  const { t } = useTranslation(['component']);

  const stockStatus: StockStatus = getStockStatus(stockLow, stock);
  const classes = useStyles({
    color: stockStatus.color,
    noData: !stock
  });

  return (
    <Box className={classes.root}>
      {stock && (
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          {stock}
        </Typography>
      )}

      <Box className={classes.status}>
        <Typography variant="caption" sx={{ fontWeight: 300 }}>
          {t(stockStatus.label)}
        </Typography>
      </Box>
    </Box>
  );
}
